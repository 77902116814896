<template>
  <Layout>

  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Main.vue';

export default {
  components: { Layout },
  data() {
    return {

    }
  }
}
</script>
